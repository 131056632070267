
body {
    background-color: #444a4e;
}

p {
    margin: 2px 0;
}

a, p, h1, h2, h3, h4, h5, li, ul, button {
    font-family:  sans-serif; 
    color: #ddd;
}

button { 
    color: #252525;
    border: none;
    margin: 5px;
    border-radius: 3px;
    box-shadow: #111 0 0 10px;
    padding: 5px 10px;
    background-color: #7faf7f;
}

#kinect {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

#kinect > div {
    margin: 5px;
}

#kinect > div > img {
    border-radius: 5px;
    box-shadow: #111 0 0 10px;
}

#info-header {
    display: flex;
    align-items: center;    
}

.sensor-title {
    font-weight: bold;
    padding-right: 5px;
}

.sensor {
    display: flex;
}